// src/app/auth/auth-guard.service.ts
import { Injectable, inject } from '@angular/core';
import { RedirectCommand, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PagesEnum } from '../models';
import { UserAccountService } from '../services/user-account.service';

@Injectable()
export class AuthGuard {
  readonly #userAccountService = inject(UserAccountService);
  readonly #router = inject(Router);

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | RedirectCommand {
    const userDetails = this.#userAccountService.userDetails();

    if (!userDetails) {
      return new RedirectCommand(this.#router.parseUrl(`/${PagesEnum.LOGIN}`));
    }

    return true;
  }
}
