import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RedirectCommand, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountTypeEnum, JwtPayloadModel } from '../models';
import { UserAccountService } from '../services/user-account.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard {
  readonly #userAccountService = inject(UserAccountService);

  canActivate(
    next: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | RedirectCommand {
    const allowedRoles = next.data.roles as Array<AccountTypeEnum>;
    const userJWTDetails: JwtPayloadModel | undefined = this.#userAccountService.userJWTDetails();

    if (userJWTDetails) {
      if (userJWTDetails.iss === environment.API_URL && allowedRoles.includes(userJWTDetails.accountType)) {
        return true;
      }
    }

    return false;
  }
}
